import React, { useEffect, useState } from "react";
import "./App.css";
import { useCallApiPost } from "./hooks/useApiPost";
import type { ChartData } from "chart.js";
import moment from "moment";
import BarChart from "./components/BarChart/BartChart";

const months = Array.from(Array(12).keys()).map((item, index) =>
  moment(new Date())
    .add(index - 1, "months")
    .format()
);

const App = () => {
  const [employeeData, setEmployeeData] = useState<any>();
  const [projectData, setProjectData] = useState<any>();
  const [chartData, setChartData] = useState<ChartData<"bar">>();

  const { sendData } = useCallApiPost();

  useEffect(() => {
    sendData(
      {
        query: `
      {
   boards(ids: 3028750562) {
    items  {
      id
      name
      state
      column_values {
        id
        description
        title
        text
      }
      subitems
      {
        id
        name
      }
    
    }
  } 
}
      `,
      },
      (data: any) => setProjectData(data?.data?.boards[0]?.items)
    );
  }, [sendData]);

  useEffect(() => {
    sendData(
      {
        query: `
        {
          boards(ids: 3033480445) {
           items  {
             id
             name
             state
             column_values {
               id
               description
               title
               text
             }         
           }
         }
       }
      `,
      },
      (data: any) => setEmployeeData(data?.data?.boards?.[0]?.items)
    );
  }, [sendData]);

  useEffect(() => {
    if (projectData && employeeData) {
      const generatedChartData = {
        labels: months.map((item) => moment(item).format("MMM YY")),
        datasets: [
          {
            type: "line" as any,
            label: "Capacity",
            borderColor: "rgb(255, 99, 132)",
            borderWidth: 2,
            fill: false,
            data: months?.map((item: any) =>
              employeeData
                .filter((employeeData2: any) => {
                  const startDate = moment(
                    employeeData2.column_values.find(
                      (item2: any) => item2.id === "date_1"
                    ).text,
                    "YYYY-MM-DD"
                  );
                  const endDate = moment(
                    employeeData2.column_values.find(
                      (item2: any) => item2.id === "date_13"
                    ).text,
                    "YYYY-MM-DD"
                  );
                  if (
                    (startDate.isSameOrBefore(moment(item)) ||
                      !startDate.isValid()) &&
                    (endDate.isSameOrAfter(moment(item)) || !endDate.isValid())
                  ) {
                    return employeeData;
                  } else return false;
                })
                .reduce((prevValue: number, currentValue: any) => {
                  return (
                    prevValue +
                    +currentValue.column_values.find(
                      (item2: any) => item2.id === "numbers9"
                    ).text
                  );
                }, 0)
            ),
          },
          {
            type: "bar" as const,
            label: "Contracted",
            backgroundColor: "rgb(75, 192, 192)",
            data: months?.map((item: any) =>
              projectData
                .filter((projectDataItem: any) => {
                  const startDate = moment(
                    projectDataItem.column_values
                      .find((item2: any) => item2.id === "timeline")
                      .text.split(" - ")[0],
                    "YYYY-MM-DD"
                  );
                  const endDate = moment(
                    projectDataItem.column_values
                      .find((item2: any) => item2.id === "timeline")
                      .text.split(" - ")[1],
                    "YYYY-MM-DD"
                  );
                  if (
                    startDate.isSameOrBefore(moment(item)) &&
                    endDate.isSameOrAfter(moment(item))
                  ) {
                    return projectDataItem;
                  } else return false;
                })
                .reduce((prevValue: number, currentValue: any) => {
                  const monthlyHours = currentValue.column_values
                    .find(
                      (item2: any) => item2.id === "subitems_weekly_allocation"
                    )
                    ?.text.split(", ")
                    .reduce(
                      (prev: string, current: string) => +prev + +current,
                      0
                    );
                  return +(prevValue + monthlyHours);
                }, 0)
            ),
            borderColor: "white",
            borderWidth: 2,
          },
        ],
      };
      if (generatedChartData) {
        setChartData(generatedChartData);
      }
    }
  }, [projectData, employeeData]);

  return chartData ? <BarChart data={chartData} /> : null;
};

export default App;
